import axios from '@/services/axios'
import { notification } from 'ant-design-vue';
import { setUserInfo} from '@/plugins/storage'

export default async (data) => {
  try {
    // return this.$router.push({ name: 'Dashboard' })
    const res = await axios.post('/api/v1/user/info', data)
    if(res.status){
      notification.success({
            message: 'Thành công',
            description:
              'Cập nhật thành công.',
        });
      setUserInfo(res.message)
    }else{
      notification.error({
            message: 'Lỗi',
            description: res.message
        });
    }
    return res
    // store.dispatch('login', res.data)
  } catch (e) {
   
  }
}
