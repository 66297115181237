<template>

	<!-- Profile Information Card -->
	<a-card v-if="info !== null" :bordered="false" class="header-solid h-full card-profile-information" :bodyStyle="{paddingTop: 0, paddingBottom: '16px' }" :headStyle="{paddingRight: 0,}">
		<template #title>
			<h6 class="font-semibold m-0">{{ $t('account.userInfo.title') }}</h6>
		</template>
		<a-button type="link" slot="extra" @click="modalProfile = true">
			<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path class="fill-muted" d="M13.5858 3.58579C14.3668 2.80474 15.6332 2.80474 16.4142 3.58579C17.1953 4.36683 17.1953 5.63316 16.4142 6.41421L15.6213 7.20711L12.7929 4.37868L13.5858 3.58579Z" fill="#111827"/>
				<path class="fill-muted" d="M11.3787 5.79289L3 14.1716V17H5.82842L14.2071 8.62132L11.3787 5.79289Z" fill="#111827"/>
			</svg>
		</a-button>
		<a-descriptions :column="1">
			<a-descriptions-item :label="$t('account.userInfo.fullName')">
				{{ info.name }}
			</a-descriptions-item>
			<a-descriptions-item :label="$t('email')">
				{{ info.email }} ({{info.email_verified_at ? $t('auth.verifyEmail.verified') : $t('auth.verifyEmail.unconfirmed')}})
			</a-descriptions-item>
			<a-descriptions-item :label="$t('account.userInfo.phoneNumber')">
				{{ info.phone }}
			</a-descriptions-item>
		</a-descriptions>
		<h6 class="font-semibold mt-10 mb-10">{{ $t('account.invoice.title') }}</h6>
		<a-descriptions :column="1">
			<a-descriptions-item :label="$t('account.invoice.companyName')">
				{{ info.company_name }}
			</a-descriptions-item>
			<a-descriptions-item :label="$t('account.invoice.taxCode')">
				{{ info.tax_code }}
			</a-descriptions-item>
			<a-descriptions-item :label="$t('account.invoice.taxRegisteredAddress')">
				{{ info.tax_address }}
			</a-descriptions-item>
			<a-descriptions-item :label="$t('account.invoice.billingAddress')">
				{{ info.address }}
			</a-descriptions-item>
			<a-descriptions-item :label="$t('account.invoice.billingEmail')">
				{{ info.tax_email }}
			</a-descriptions-item>
			<a-descriptions-item :label="$t('account.invoice.billingPhone')">
				{{ info.tax_phone }}
			</a-descriptions-item>
		</a-descriptions>
		<a-modal v-model="modalProfile" :title="$t('account.invoice.title')" class="modalProfile" @ok="handleSubmit">
		    <a-form
				id="components-form-demo-normal-login"
				:form="form"
				class="login-form">
				<a-form-item class="mb-10">
					<a-input
						v-decorator="['name']"
						:placeholder="$t('account.userInfo.fullName')"
					>
					</a-input>
				</a-form-item>
				<a-form-item class="mb-10">
					<a-input
						v-decorator="['phone']"
						:placeholder="$t('account.userInfo.phoneNumber')"
					>
					</a-input>
				</a-form-item>
				<a-form-item class="mb-10">
					<a-input
						v-decorator="['companyName']"
						:placeholder="$t('account.invoice.companyName')"
					>
					</a-input>
				</a-form-item>
				<a-form-item class="mb-10">
					<a-input
						v-decorator="['taxCode']"
						:placeholder="$t('account.invoice.taxCode')"
					>
					</a-input>
				</a-form-item>
				<a-form-item class="mb-10">
					<a-input
						v-decorator="['taxRegisteredAddress']"
						:placeholder="$t('account.invoice.taxRegisteredAddress')"
					>
					</a-input>
				</a-form-item>
				<a-form-item class="mb-10">
					<a-input
						v-decorator="['billingAddress']"
						:placeholder="$t('account.invoice.billingAddress')"
					>
					</a-input>
				</a-form-item>
				<a-form-item class="mb-10">
					<a-input
						v-decorator="['billingEmail']"
						:placeholder="$t('account.invoice.billingEmail')"
					>
					</a-input>
				</a-form-item>
				<a-form-item class="mb-10">
					<a-input
						v-decorator="['billingPhone']"
						:placeholder="$t('account.invoice.billingPhone')"
					>
					</a-input>
				</a-form-item>
				<!-- <a-form-item>
					<a-button type="primary" block html-type="submit" class="login-form-button">
						Cập nhật
					</a-button>
				</a-form-item> -->
			</a-form>
	    </a-modal>
	</a-card>
	<!-- / Profile Information Card -->
</template>
<!-- <style type="text/css">
	.modalProfile .ant-modal-footer{
		display: none;
	}
</style> -->
<script>
	import updateInfo from '@/services/user/updateInfo'
	import {setUserInfo} from '@/plugins/storage'

	export default ({
		data() {
		    return {
		      modalProfile: false,
		    };
		},
		props: {
			info: {
				type: Object,
				default: null
			}
		},
		beforeCreate() {
			// Creates the form and adds to it component's "form" property.
			this.form = this.$form.createForm(this, { name: 'normal_login' });
		},
		methods: {
			// Handles input validation after submission.
			handleSubmit(e) {
				// e.preventDefault();
				let thiss = this
				this.form.validateFields(async (err, values) => {
					if ( !err ) {
						let formData = new FormData();
						formData.append('name', values.name || '');
						formData.append('phone', values.phone || '');
						formData.append('company_name', values.companyName || '');
						formData.append('tax_code', values.taxCode || '');
						formData.append('tax_address', values.taxRegisteredAddress || '');
						formData.append('tax_email', values.billingEmail || '');
						formData.append('tax_phone', values.billingPhone || '');
						formData.append('address', values.billingAddress || '');
						const result = await updateInfo(formData)
						this.modalProfile = false
						setUserInfo(result.message)
						this.$emit('reloadInfomation')
					}	
				});
			},
		},
	})

</script>