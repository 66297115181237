<!-- 
	This is the user profile page, it uses the dashboard layout in: 
	"./layouts/Dashboard.vue" .
 -->

<template>
	<div v-if="record_info !== null">

		<!-- Header Background Image -->
		<div class="profile-nav-bg" style="background-image: url('images/bg-profile.jpg')"></div>
		<!-- / Header Background Image -->

		<!-- User Profile Card -->
		<a-card :bordered="false" class="card-profile-head" :bodyStyle="{padding: 0,}">
			<template #title>
				<a-row type="flex" align="middle">
					<a-col :span="18" :md="20" class="col-info">
						<a-avatar :size="74" shape="square" src="images/avatar-default.png" />
						<div class="avatar-info">
							<h4 class="font-semibold m-0">{{ record_info.name }}</h4>
							<p>{{ record_info.email }}</p>
						</div>
					</a-col>
					<a-col :span="6" :md="4" class="text-right">
						<a-button type="primary" @click="modal = !modal">Nạp tiền</a-button>
					</a-col>
				</a-row>
			</template>
		</a-card>
		<!-- User Profile Card -->

		<a-row type="flex" :gutter="24">

			<!-- Profile Information Column -->
			<a-col :span="24" :md="16" class="mb-24">

				<!-- Profile Information Card -->
				<CardProfileInformation :info="record_info" @reloadInfomation="getData"/>
				<!-- / Profile Information Card -->

			</a-col>
			<!-- / Profile Information Column -->

			<!-- Platform Settings Column -->
			<a-col :span="24" :md="8" class="mb-24">

				<!-- Platform Change Pass Card -->
				<CardProfileChangePass />
				<!-- / Platform Change Pass Card -->

			</a-col>
			<!-- / Platform Change Pass Column -->

			
		</a-row>
		<modal-qrcode :visible="modal"
			@close="modal = false"/>
	</div>
</template>

<script>

	import CardProfileChangePass from "@/components/Cards/CardProfileChangePass"
	import CardProfileInformation from "@/components/Cards/CardProfileInformation"
	import ModalQrcode from "@/components/Modal/ModalQrcode"
	import {getUserInfo} from '@/plugins/storage'

	export default ({
		components: {
			CardProfileChangePass,
			CardProfileInformation,
			ModalQrcode,
		},
		data() {
			return {
				record_info: {
					name: 'Phan Văn Tâm',
					email: 'tampv.bg@gmail.com',
					phone: '0386 681 574',
				},
				modal : false
			}
		},
		created(){
			this.getData()
		},
		methods: {
			getData(){
				this.record_info = getUserInfo()
			}
		}
	})

</script>

<style lang="scss">
</style>