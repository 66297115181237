<template>

	<!-- Profile Information Card -->
	<a-card :bordered="false" class="header-solid h-full card-profile-information" :bodyStyle="{paddingTop: 0, paddingBottom: '16px' }" :headStyle="{paddingRight: 0,}">
		<template #title>
			<h6 class="font-semibold m-0">{{ $t('account.password.title') }}</h6>
		</template>
		<a-form
			id="components-form-demo-normal-login"
			:form="form"
			class="login-form"
			@submit="handleSubmit"
		>
			<a-form-item class="mb-10">
				<a-input
					v-decorator="[
					'oldPassword',
					{ rules: [{ required: true, message: 'Please input old password!' }] },
					]"
					type="password"
					:placeholder="$t('account.password.old')"
				>
				</a-input>
			</a-form-item>
			<a-form-item class="mb-10">
				<a-input
					v-decorator="[
					'password',
					{ rules: [{ required: true, message: 'Please input your new password!' }] },
					]"
					type="password"
					:placeholder="$t('account.password.new')"
				>
				</a-input>
			</a-form-item>
			<a-form-item class="mb-5">
				<a-input
					v-decorator="[
					'rePassword',
					{ rules: [{ required: true, message: 'Please input your Password!' }] },
					]"
					type="password"
					:placeholder="$t('account.password.rePassword')"
				>
				</a-input>
			</a-form-item>
			<a-form-item>
				<a-button type="primary" block html-type="submit" class="login-form-button">
					{{ $t('account.password.btn') }}
				</a-button>
			</a-form-item>
		</a-form>
	</a-card>
	<!-- / Profile Information Card -->

</template>

<script>
	import changePass from '@/services/user/changePass'
	import { notification } from 'ant-design-vue'

	export default ({
		beforeCreate() {
			// Creates the form and adds to it component's "form" property.
			this.form = this.$form.createForm(this, { name: 'normal_login' });
		},
		methods: {
			// Handles input validation after submission.
			handleSubmit(e) {
				e.preventDefault();
				let thiss = this
				this.form.validateFields(async (err, values) => {
					if ( !err ) {
						if (values.password !== values.rePassword) {
							notification.error({
								message: this.$t('auth.resetPassword.passwordConfirm.notmatch')
							})
							return
						}
						let formData = new FormData();
						formData.append('oldPassword', values.oldPassword);
						formData.append('password', values.password);
						formData.append('rePassword', values.rePassword);
						const result = await changePass(formData)
						if(result.status == 1){
							thiss.form.resetFields()
						}
					}
				});
			},
		},
	})

</script>